import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import * as React from 'react';
import useLayoutEffect from "rc-util/es/hooks/useLayoutEffect";
import canUseDom from "rc-util/es/Dom/canUseDom";
import OrderContext from "./Context";
var EMPTY_LIST = [];
/**
 * Will add `div` to document. Nest call will keep order
 * @param render Render DOM in document
 */

export default function useDom(render, debug) {
  var _React$useState = React.useState(function () {
    if (!canUseDom()) {
      return null;
    }

    var defaultEle = document.createElement('div');

    if (process.env.NODE_ENV !== 'production' && debug) {
      defaultEle.setAttribute('data-debug', debug);
    }

    return defaultEle;
  }),
      _React$useState2 = _slicedToArray(_React$useState, 1),
      ele = _React$useState2[0]; // ========================== Order ==========================


  var appendedRef = React.useRef(false);
  var queueCreate = React.useContext(OrderContext);

  var _React$useState3 = React.useState(EMPTY_LIST),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      queue = _React$useState4[0],
      setQueue = _React$useState4[1];

  var mergedQueueCreate = queueCreate || (appendedRef.current ? undefined : function (appendFn) {
    setQueue(function (origin) {
      var newQueue = [appendFn].concat(_toConsumableArray(origin));
      return newQueue;
    });
  }); // =========================== DOM ===========================

  function append() {
    if (!ele.parentElement) {
      document.body.appendChild(ele);
    }

    appendedRef.current = true;
  }

  function cleanup() {
    var _ele$parentElement;

    (_ele$parentElement = ele.parentElement) === null || _ele$parentElement === void 0 ? void 0 : _ele$parentElement.removeChild(ele);
    appendedRef.current = false;
  }

  useLayoutEffect(function () {
    if (render) {
      if (queueCreate) {
        queueCreate(append);
      } else {
        append();
      }
    } else {
      cleanup();
    }

    return cleanup;
  }, [render]);
  useLayoutEffect(function () {
    if (queue.length) {
      queue.forEach(function (appendFn) {
        return appendFn();
      });
      setQueue(EMPTY_LIST);
    }
  }, [queue]);
  return [ele, mergedQueueCreate];
}