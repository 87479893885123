import _extends from 'babel-runtime/helpers/extends';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
var __rest = this && this.__rest || function (s, e) {
    var t = {};
    for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
    }return t;
};
import * as React from 'react';
import { TweenOneGroup } from 'rc-tween-one';
import classnames from 'classnames';
import animTypes from './animTypes';
function transformArguments(arg, e) {
    var result = void 0;
    if (typeof arg === 'function') {
        result = arg(e);
    } else {
        result = arg;
    }
    return result;
}

var TextyAnim = function (_React$Component) {
    _inherits(TextyAnim, _React$Component);

    function TextyAnim() {
        _classCallCheck(this, TextyAnim);

        var _this = _possibleConstructorReturn(this, _React$Component.apply(this, arguments));

        _this.getChildrenToRender = function (str) {
            if (!str) {
                return [];
            }
            var split = _this.props.split;

            var t = split ? split(str) : str.split(''); // Array(str.length).fill(1);
            return t.map(function (c, i) {
                return React.createElement("span", { key: c + '-' + i.toString() }, c);
            });
        };
        _this.getEnterOrLeave = function (e, genre, length) {
            var _this$props = _this.props,
                mode = _this$props.mode,
                type = _this$props.type,
                enter = _this$props.enter,
                appear = _this$props.appear,
                interval = _this$props.interval,
                duration = _this$props.duration;

            if (!appear && genre === 'enter' || length < 0) {
                return null;
            }
            var cb = _extends({}, e, { type: genre });
            var $duration = transformArguments(duration, cb);
            var $interval = transformArguments(interval, cb);
            var delay = void 0;
            if (typeof interval === 'function') {
                // function 下 mode 无效；
                delay = $interval;
            } else {
                switch (mode) {
                    case 'reverse':
                        delay = (length - e.index) * $interval;
                        break;
                    case 'sync':
                        delay = 0;
                        break;
                    case 'random':
                        delay = length * $interval * Math.random();
                        break;
                    default:
                        delay = e.index * $interval;
                }
            }
            delay += transformArguments(_this.props.delay, cb);
            var genreType = genre === 'enter' ? 'from' : 'to';
            var custom = _this.props[genre] || enter;
            if (custom && typeof custom === 'function') {
                custom = custom(_extends({}, e, { delay: delay }));
            } else if (!custom) {
                custom = animTypes[type];
            }
            if (custom.enter) {
                custom = custom[genre] || custom.etner;
            }
            if (Array.isArray(custom)) {
                return custom.map(function (item, i) {
                    if (!i && (!item.duration || item.type === 'set')) {
                        return item;
                    } else if (i === 1) {
                        var preItem = custom[0];
                        if (!preItem.duration || item.type === 'set') {
                            return _extends({ delay: delay }, item);
                        }
                    }
                    return _extends({ delay: !i ? delay : 0 }, item);
                });
            }
            return _extends({ delay: delay, duration: $duration, type: genreType }, custom);
        };
        return _this;
    }

    TextyAnim.prototype.render = function render() {
        var _classnames,
            _this2 = this;

        var _a = this.props,
            prefixCls = _a.prefixCls,
            type = _a.type,
            className = _a.className,
            enter = _a.enter,
            mode = _a.mode,
            duration = _a.duration,
            delay = _a.delay,
            interval = _a.interval,
            children = _a.children,
            split = _a.split,
            props = __rest(_a, ["prefixCls", "type", "className", "enter", "mode", "duration", "delay", "interval", "children", "split"]);
        var getChildrenToRender = this.getChildrenToRender(children);
        var classNames = classnames(prefixCls, (_classnames = {}, _classnames[type] = type && !enter, _classnames[className] = !!className, _classnames));
        return React.createElement(TweenOneGroup, _extends({}, props, { className: classNames, enter: function enter(e) {
                return _this2.getEnterOrLeave(e, 'enter', getChildrenToRender.length - 1);
            }, leave: function leave(e) {
                return (
                    // 出场时 children 是没有， 需取 group 里的  keysToLeave
                    _this2.getEnterOrLeave(e, 'leave', _this2.tweenGrooup.keysToLeave.length - 1)
                );
            }, ref: function ref(c) {
                _this2.tweenGrooup = c;
            } }), getChildrenToRender);
    };

    return TextyAnim;
}(React.Component);

export default TextyAnim;

TextyAnim.defaultProps = {
    type: 'top',
    mode: 'smooth',
    prefixCls: 'texty',
    component: 'div',
    delay: 0,
    interval: 50,
    appear: true
};