/** Minimum scale */
export var MIN_SCALE = 1;
/** Maximum Scale */

export var MAX_SCALE = 50;
/** Scale the ratio base */

export var BASE_SCALE_RATIO = 1;
/** The maximum zoom ratio when the mouse zooms in, adjustable */

export var WHEEL_MAX_SCALE_RATIO = 0.2;